<template>

  <div class="about">
    
    <p ><strong style="color:green;">{{ $t('about') }}</strong></p>

  </div>
  <div>
  <img id="en-home-img" name="en-home-img" alt="en-home-img" :src="$t('home_img')"  fetchpriority="high" width="474" height="634"/>

  <p v-tooltip="`Tango Helper App`">
    
    <a href="https://tango.krucha.com" target="_blank" rel="noopener" style="color: #485fc7;" ><strong>Tango For All</strong></a>
  </p>      
  </div>

 
</template>

<script>
import i18n from '@/i18n'

export default {
  name: "About",

  data() {
    return {
      imgHome: i18n.global.t('home_img')
    }
  },
  created () {
    console.log("created locale", this.$i18n.locale)
  }
     
};
</script>
